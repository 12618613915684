.forgot-password-email-button {
    color: #ffffff;
    text-align: center;
    background: $secondary-color 0% 0% no-repeat padding-box;
    border-radius: 10px;
    height: 48px;
    border: none;
    min-width: none;
    width: 212px;
}

.forgot-password-email-button:hover {
    background: #622F92 0% 0% no-repeat padding-box;
}

.email-input {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
}