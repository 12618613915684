* {
  font-family: $font-family;
}

p {
  font-size: $paragraph-font-size;
  color: $text-color;
}

.password-requirements{
  color: $primary-color
}
.hyperlink-color{
  color: $hyperlink-color
}
.primary-color{
  color: $primary-color
}
.eligibility-criteria-section-text-color{
  color: $eligibility-criteria-section-text-color
}
