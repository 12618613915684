@mixin button-active-state {
  background-color:  #622F92 !important;
  color: #fff !important;
}

@mixin button-disabled-state {
  background-color: $primary-button-color;
  border-color: $primary-button-color;
  color: #fff;
}

button {
  line-height: 34px !important;
  font-weight: lighter;
  min-width: 243px;
  text-align: center;

  &.button {
    &--primary {
      background-color: $primary-button-color;
      border-color: $primary-button-color;
      color: #fff;
      font-size: $button-font-size;

      &:hover {
        @include button-active-state;
      }

      &:active {
        @include button-active-state;
      }

      &:focus-visible { 
        @include button-active-state;
      }

      &.disabled {
        @include button-disabled-state;
        &:disabled {
          @include button-disabled-state;
        }
      }
      &:disabled {
        @include button-disabled-state;
      }
    }

    &--dense {
      width: 100%;
      min-width: unset;
    }
  }
}

.grid-button {
  min-width: 100px;
}
