@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,300;1,400;1,700&display=swap');

$gray-100: #E0E0E0;
$gray-200: #F3F3F3;
$gray-500: #707070;

$primary-color: #24226A;
$secondary-color: #F55B41;
$text-color: #707070;
$hyperlink-color: #24226A;
$error-color: #C82333;

$primary-button-color: #F55B41;

$success-step-color: #F55B41;


$font-family: 'Roboto', sans-serif;

$h2-font-size: 24px;
$h3-font-size: 16px;
$paragraph-font-size: 16px;
$label-font-size: 16px;
$button-font-size: 16px;

$input-border-color: $gray-100;

$contact-box-background-color: #24226A0D;
$needhelp-box-background-color: #24226A0D;
$welcome-banner-background-color: #F3F3F7;

$ag-grid-data-color:#535458;
$ag-grid-first-row-background-color:#F3F3F3;
$ag-grid-second-row-background-color:#ffffff;
$ag-grid-header-text-color:#24226A;
$ag-grid-dropdown-active-item-background-color:#24226A;

$eligibility-criteria-section-text-color: #707070;
