input, select {
  height: 48px;
  border: 1px solid $input-border-color;
  border-radius: 5px;
}

label {
  font-size: $label-font-size;
  font-weight: bold;
  color: $text-color;
}

.label {
  &__radio {
    margin-right: 15px;
    font-weight: normal;
    input {
      margin-right: 5px;
    }
  }
}

.form-group {
  margin-bottom: 20px;
}

.form-label {
  position: relative;
  width: 100%;
  font-size: 16px;
  .optional-text {
    position: absolute;
    right: 0;
    font-weight: 100;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.info-icon {
  padding: 2px 6px;
  margin: 0 5px;
  font-size: 9px;
  border: 1px solid $primary-color;
  border-radius: 33.33px;
  line-height: 16px;
  text-align: center;
  position: relative;
  bottom: 2px;
  color: $primary-color;
}


.invalid-feedback-input {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #d64638;
}
