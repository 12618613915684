h2 {
  font-size: $h2-font-size;
  color: $primary-color;
  font-weight: 700;
  margin-bottom: 15px;
}

h3 {
  font-size: $h3-font-size;
  color: $primary-color;
  font-weight: 700;
  margin-bottom: 7px;
}