
/* Popup style */
.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1022;
  }

  .popup-header {
    font-size: 24px;
    letter-spacing: 0px;
    color: $primary-color;
    opacity: 1;
    margin-bottom: 25px;
  }
   
  .popup-box-container {
    position: relative;
    width: 65%;
    height: auto;
    max-height: 80vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 10px;
    padding: 50px;
    border: 1px solid #999;
    overflow: auto;
    left: 50%;
    transform: translate(-50%, 0px);
  }


  /* New Popup box styles */

  .link-box {
    width: 90%;
    height: fit-content;
    overflow: hidden;
  }

  .popup-box-heading {
    color: $primary-color;
    text-decoration: none;
    font-family: 'Roboto-Bold',Arial;
    font-size: 13.5pt;
    width: fit-content;
  }

  .cross-button {
    text-decoration: none;
    font-family: 'Roboto-Bold',Arial;
    font-size: 13.5pt;
    color: #707070;
    width: fit-content;
    cursor: pointer;
  }

  .popup-box-header {
    display: flex;
    justify-content: space-between;
  }

  .link-text {
    padding: 0px 30px;
    color: #707070;
    text-decoration: none;
    font-family: 'Roboto',Arial;
    font-size: 12.0pt;
    overflow-y: scroll !important;
    max-height: 350px;
  }

  .popup-box-footer {
    display: flex;
    flex-direction: row-reverse;
    margin: 10px 0px;
  }

  .popup-box-footer > button {
    min-width: 0px;
    width: 100px;
    border: none;
    background-color: $primary-button-color;
    color:#FFFFFF;
    font-family: 'Roboto-Regular',Arial;
    font-size: 12.0pt;
    border-radius: 7px;
  }

  .link-paragraph-text, .link-paragraph-text p  {
    color: #707070;
    text-decoration: none;
    font-family: 'Roboto-Regular',Arial;
    font-size: 0.75rem;
  }

  .link-paragraph-text ul {
    padding-left: 1rem;
  }

  .link-paragraph-heading {
    color: #707070;
    text-decoration: underline;
    font-family: 'Roboto',Arial;
    font-weight: bold;
    font-size: 0.75rem;
  }