.eligibility {
  &__welcome-banner {
    background-color: $welcome-banner-background-color;
    padding: 20px;
    border: none;
    margin-bottom: 90px;
    h2 {
      font-size: 20px;
    }
    p {
      margin: 0;
    }
  }
  &.registration__breadcrumb {
    position: static;
  }
  &__coming-soon-wrapper {
    height: 100%;
    padding-top: 100px;
    margin-right: -17.33333333%;
    position: relative;
    top: 40px;
  }
}

.user-first-name {
  text-transform: capitalize;
  color: inherit;
}

.eligibility-result {
  font-size: 18px;
  .highlighted-primary-text {
    color: $primary-color;
    font-weight: 700;
  }
  &__heading {
    font-size: 16px;
  }
  &__body {
    .table-wrapper {
      .table-contain {
        border-collapse: unset;
        border: 1px solid #707070;
        border-radius: 5px;
        opacity: 1;
        border-spacing: 0px;
        &__label {
          font-size: 16px;
          color: #68667c;
        }
        &__value {
          font-size: 20px;
        }

        tbody tr td {
          padding: 5px 15px;
        }

        tbody tr:nth-child(even){
          background-color: #E1E1E1;
        }
      }
  }
    &__footer {
      font-size: 18px;
    }
  }
}

.eligibility-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  &__image {
    height: 300px;
    margin-bottom: 100px;
    img {
      height: 100%;
      width: auto;
    }
  }
  &__content {
    width: 70%;
    text-align: center;
    margin-bottom: 150px;
  }
  &__title {
    color: $success-step-color;
  }
}

.highlighted-secondary-text {
  color: $secondary-color;
  font-weight: 700;
}

.highlighted-pink {
  color : #622F92
}