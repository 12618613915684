.contact {
  border: 1px solid $primary-color;
  padding: 15px;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: $contact-box-background-color;
  gap: 15px;
  font-size: 14px;
  overflow-x: auto;
  p {
    margin-bottom: 0;
    font-size: inherit;
  }
}