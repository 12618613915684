.layout {
  position: relative;

  &__wrapper {
    display: flex;
  }

  &__body {
    min-height: 100vh;
    padding: 40px 0;
  }

  &__nav {
    padding: 40px 45px;
    background-color: #F3F3F3;
    height: 100vh;
    position: sticky;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__logo {
    img {
      width: 100%;
      height: auto;
      max-width: 250px;
    }
  }

  &__content {
    display: flex;
    justify-content: end;
    flex-direction: column;
    gap: 50px;
    flex-basis: 50%;
    position: relative;
  }
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  color: #fff;
  // background-image: url("../assets/images/RepeatGrid.png"),linear-gradient(180deg, #0079C2 0%, #004D7B 100%) ;
  background-size: contain;
  background-color: $primary-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 60px;
  padding: 15px 60px;
  z-index: 9999;

  a {
    color: inherit;
  }

  &__image {
    height: 50px;

    img {
      height: 100%;
      width: auto;
    }
  }

  &__privacy-text {
    padding-right: 30px;

    p {
      color: #fff;

      &:last-child {
        margin: 0;
      }
    }
  }

  &__policy_link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;

    p {
      color: $hyperlink-color;
      cursor: pointer;
      color: #fff;
      text-decoration: underline;
      white-space: nowrap;
      margin: 0;
    }
  }
}