
@import '~ag-grid-community/dist/styles/ag-grid.css';
@import '~ag-grid-community/dist/styles/ag-theme-alpine.css';
@import '~ag-grid-community/dist/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin';
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

.ag-theme-alpine {
  // https://www.ag-grid.com/javascript-grid-themes-customising/#base-theme-parameters
  @include ag-theme-alpine((
    // Colour of text in
    data-color: $ag-grid-data-color ,
    // Colour of text and icons in
    header-foreground-color: $ag-grid-header-text-color,
    // Background colour o
    background-color: $ag-grid-first-row-background-color,
    // Background colour for all headers, including the grid header,
    header-background-color: #fff,
    // Background colour applied to every other row or null to use background-color fo
    odd-row-background-color: $ag-grid-second-row-background-color,
    // Background color when hovering over rows in the grid and in dropdown menus, or null for no rollover effect (note - if you want a rollover on one but not the other, set to null and use CSS to achieve the
    row-hover-color: ag-derived(header-foreground-color, $opacity: 0.13)
  ));

  .ag-root-wrapper {
    min-height: fit-content;
    overflow: visible;
  }

  .ag-header-cell {
    .ag-header-cell-text {
      font-size: 12px;
      font-weight: bold;
      font-family: "Open Sans", sans-serif;
      display: flex;
      flex-direction: column;
      justify-content: center;
      white-space: normal;
    }
  }

  .ag-cell.ag-cell-value {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 0.6875rem;
    white-space: normal;
    line-height: 0.9rem;
  }

  // TODO: Determine styling to headers, if desired
  // .ag-header-cell-filtered {
    // background-color: $cmx-green;
    // color: white;
    // background-color: $gray-200;
  // }

  .dropdown-item.active {
    background-color: #622F92;
  }
}
