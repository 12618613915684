.registration-form {
  &__wrapper {
    padding-bottom: 40px;
  }
  &__info-text {
    align-self: start;
    position: relative;
    font-weight: 600;
    top: 45px;
  }
}

.registration {
  &__side-nav {
    padding: 30px 45px;
    background-color: #F3F3F3;
    position: sticky;
    height: 100%;
    right: 0;
    top: 0;
  }
  &__logo {
    margin-bottom: 175px;
    img {
      width: 100%;
      height: auto;
    }

  }
  &__breadcrumb {
    position: absolute;
    top: 0;
    list-style: none;
    padding-left: 0;
    li {
      padding: 5px 0;
      display: flex;
      align-items: center;
      text-align: left;
      margin-bottom: 10px;

      .icon {
        margin-right: 20px;
        width: 30px;
        font-size: 24px;

        &--result {
          position: relative;
        }
      }
    }
  }

  &__active-step {
    color: $primary-color;
    font-weight: 600;
  }

  &__success {
    color: $success-step-color;
  }

  &__success-image {
    width: 100%;
    margin-top: 75px;
    img {
      width: 100%;
      height: auto;
    }
  }

  &__contact-wrapper {
    margin-top: 40px;
  }

  &__icon {
    height: 20px;
    img {
      height: 100%;
      width: auto;
      position: relative;
      bottom: 4px
    }
  }

  &__step {
    display: flex;
    align-items: flex-start;
    gap: 20px;
  }

  &__content {
    display: flex;
  }

  &__image-container {
    width: 38px;
    height: 30px;
    margin-right: 10px;

    img {
      height: 30px;
    }
  }
}