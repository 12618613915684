.app-nav {
  .nav-item {
    display: flex;
    margin: 5px;
    align-items: center;

    &__icon-wrapper {
      width: 30px;
      > img {
        height: 25px;
        width: auto;
      }
    }

    &__logout {
      > div {
        display: flex;
        flex: 1;
      }
    }

    &__logout-icon {
      color: $secondary-color;
    }
  }

  .nav-link {
    letter-spacing: 0.71px;
    color: $primary-color;
    opacity: 1;
    padding-top: 0px;
    padding-bottom: 0;
    padding-left: 20px;
  }

  .nav-link-logout {
    letter-spacing: 0.71px;
    color: $secondary-color;
    opacity: 1;
    padding-top: 0px;
    padding-bottom: 0;
    padding-left: 20px;
  }

  .side-nav {
    .nav-item {
      border-bottom: 1px solid #7070702e;
      padding: 11px 0;
      cursor: pointer;
      margin: 0;
      &:last-child {
        border: none;
      }
      a {
        display: flex;
        margin: 5px;
        align-items: center;
        text-decoration: none;
      }
    }
  }
}