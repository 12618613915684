.need-help {
  padding: 20px 15px;
  border: 1px solid $primary-color;
  background: $needhelp-box-background-color 0% 0% no-repeat padding-box;
  width: fit-content;
  .icon {
    margin-right: 15px;
  }
  p {
    margin: 0;
  }
  &__header {
    display: flex;
    gap: 15px;
    align-items: center;
    margin-bottom: 15px;
    h2 {
      margin-bottom: 0;
    }
  }
  &__image-wrap {
    width: 24px;
    img {
      width: 30px;
      height: auto;
    }
  }
}

.need-help-negative-scenario {
  padding: 20px 15px;
  border: 1px solid $secondary-color;
  background: $needhelp-box-background-color 0% 0% no-repeat padding-box;
  width: fit-content;
  .icon {
    margin-right: 15px;
  }
  p {
    margin: 0;
  }
  &__header {
    display: flex;
    gap: 15px;
    align-items: center;
    margin-bottom: 15px;
    h2 {
      margin-bottom: 0;
    }
  }
  &__image-wrap {
    width: 24px;
    img {
      width: 30px;
      height: auto;
    }
  }
}