.attention {
    padding: 15px;
    background-color: #f55b41;
    font-size: 14px;
    width: 100vw;
    bottom: 0;
    position: absolute;
    left: 0;
    p {
        margin-bottom: 0;
        font-size: inherit;
        color: black;
    }
}