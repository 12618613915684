

.login-left-panel {
    background-color: $gray-200;
    background: url('../assets/images/auth_screen_1.png') no-repeat bottom right;
    background-size: 50% 50%;
}

.login-panel-small-text {
    font-size: 0.7rem;
    margin: 0px;
}

.login{
    &__wrapper {
        background-color: $gray-200;
        padding: 83px 103px;
        text-align: center;
        label {
            text-align: left;
        }
        h2 {
            text-align: left;
        }
        .invalid-feedback {
            text-align: left;
        }
        form {
            p {
                &:last-child {
                    margin-left: -80px;
                    margin-right: -80px;
                    margin-bottom: 0;
                }
            }
        }
    }
    &__btn {
        margin-top: 40px;
        margin-bottom: 30px;
        min-width: 60%;
    }
}

.logo {
    position: relative;
}

.logo_image {
    height: 89px;
    width: auto;
    text-align: right;
    margin-right: 57px;
    img {
        height: 100%;
        position: relative;
    }
}

.login-header {
    font-size: 1rem;
    font-weight: bold;
    color: #622F92;
    text-align: left;
    opacity: 1;
    margin-bottom:5px;
}

.login-text {
    color: #707070;
    width: 596px;
    opacity: 1;
}

.login-right-panel-header {
   color: $primary-color;
   font-size: 1rem;
   font-weight: bold;
   opacity: 1;
   letter-spacing: 0.24px;
}

.login-button {
    background-color: $secondary-color !important;
    border: none !important;
    text-align: center !important;
    width: 50%;
    font-size: 0.7rem;
    background: $secondary-color 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    min-width: 10px;
    height: 10%;
    color: #FFFFFF;
}

.login-label {
    font-weight: bold;
    letter-spacing: 0.32px;
    color: #707070;
}

.forget-password-login {
    text-decoration: underline;
    font-weight: normal;
    color: $primary-color;
    letter-spacing: 0.32px;
    margin: 1rem auto;
    cursor: pointer;

}

.line-1 {
    height: 1px;
    background-color: $primary-color;
    width: 50px;
    margin: 1rem auto;
}

.login-footer {
    background: transparent url("../assets/images/RepeatGrid.png") 0% 0% no-repeat padding-box;
    background-color: $hyperlink-color;
    height: fit-content;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: $gray-200;
}

.powerd-by-lookup {
    width: 190px;
    height: 48px;
}

.data-privacy-text {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.white-text {
    color: $gray-200;
}

.white-text:hover {
    color: $gray-200;
}

input.error {
    border-color: red;
  }
  
.input-feedback {
    color: $error-color;
    font-size: 0.7rem;
    margin-bottom: 20px;
    letter-spacing: 0.32px;
    opacity: 1;
    text-align: left;
    margin-bottom: 10px;
}

.required:after {
    content:" *";
    color: $error-color;
  }