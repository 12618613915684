.password-input {
    height: 48px;
}

.create-password-button {
    background-color: $secondary-color !important;
    border: none !important;
    text-align: center !important;
    width: 0.5rem;
    font-size: 0.7rem;
}

.create-password-checkbox-text {
    font-weight: normal;
    font-size: 16px;
    color: #686E7C;
    display: inline;
}

.create-password-checkbox-hyperlinks {
    color: #0079C2;
    font-size: 16px;
    cursor: pointer;
}

.assistance-box {
    display: flex;
    background: #8DC63F0D 0% 0% no-repeat padding-box;
    border: 1px solid #8DC63F;
    border-radius: 5px;
    opacity: 1;
    height: 59px;
    justify-content: space-evenly;
    align-items: center;
    font-size: 0.8rem;
    width: 850px;
    margin: 10px;
}

.comply_text {
    font-size: 16px;
    letter-spacing: 0.32px;
    color: #686E7C;
    opacity: 1;
}

.pc {
    &__list {
        margin-left: 15px;
        color: $text-color;
    }
}